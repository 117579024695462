<template>
  <component :is="allowAutomaticSubPageRedirection ? 'AppBarRouterView' : 'div'"
    :routerViewProps="{ allowed: allowRestrictedDataAccess }">
    <!-- Trick to replace entire page if on mobile -->
    <v-container fluid fill-height class="pa-0">
      <v-row style="height: inherit" no-gutters>
        <v-col cols="12">
          <PublicModeBanner>
            <template v-slot:loginform>
              <v-card max-width="80%" min-width="60%" flat>
                <LoginForm v-if="!isLoggedIn" @LoginSuccess="validateRedirect(true)" />
                <div v-else-if="userInfo">
                  <v-card class="pa-3  d-flex flex-column justify-center align-center">
                    <Avatar :fullname="userInfo?.Name" color="red" class="mb-1" />
                    <div>
                      {{ userInfo?.Name }}
                    </div>
                    <div>
                      {{ userInfo?.Email }}
                    </div>
                  </v-card>
                  <div class="mt-4" v-if="!youHaveOwnership && !failedRequest">
                    <v-alert type="info" style="font-size: 13px;">
                      {{ $t('text.not_your_equipment') }}
                    </v-alert>
                  </div>
                  <WideBtn :text="$t('text.go_to_your_equipment')" color="var(--v-tertiary-base)"
                    @click="$router.push({ name: 'MachineList' })">
                  </WideBtn>
                  <WideBtn :text="$t('text.logout')" color="var(--v-tertiary-base)" outlined
                    @click="$store.dispatch('logout')">
                  </WideBtn>
                </div>
              </v-card>
            </template>
          </PublicModeBanner>
        </v-col>

        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-col v-if="!loading" cols="12" lg="4" style="background-color: #FFFFFF !important;"
            :style="isPcBool ? 'overflow-y: auto; height: calc(100vh - 83px);' : ''"
            class="d-flex flex-column align-center justify-space-between">
            <LanguageSelector class="mb-4 mt-5" />
            <v-card max-width="80%" min-width="60%" flat>
              <LoginForm v-if="!isLoggedIn" @LoginSuccess="validateRedirect(true)" />
              <div v-else-if="userInfo">
                <v-card class="pa-3  d-flex flex-column justify-center align-center">
                  <Avatar :fullname="userInfo?.Name" color="red" class="mb-1" />
                  <div>
                    {{ userInfo?.Name }}
                  </div>
                  <div>
                    {{ userInfo?.Email }}
                  </div>
                </v-card>
                <div class="mt-4" v-if="!youHaveOwnership && !failedRequest">
                  <v-alert type="info" style="font-size: 13px;">
                    {{ $t('text.not_your_equipment') }}
                  </v-alert>
                </div>
                <WideBtn :text="$t('text.go_to_your_equipment')" color="var(--v-tertiary-base)"
                  @click="$router.push({ name: 'MachineList' })">
                </WideBtn>
                <WideBtn :text="$t('text.logout')" color="var(--v-tertiary-base)" outlined
                  @click="$store.dispatch('logout')">
                </WideBtn>
              </div>
            </v-card>
            <ServiceProviderContactCard class="pa-4" flat />
          </v-col>
        </template>


        <template v-if="loading">
          <v-progress-circular indeterminate class="mx-auto" style="margin-top: 30%;" size="130" width="4"
            color="primary">
          </v-progress-circular>
        </template>

        <!-- Request failed content -->
        <template v-else-if="failedRequestConfig">
          <v-col cols="12" lg="8" class="d-flex align-center justify-center"
            style="background-color: #FBFBFB !important;">
            <EmptyPage :icon="failedRequestConfig.icon" :title="failedRequestConfig.title">
              <template v-slot:message>
                <p v-html="failedRequestConfig.subtitle" />
              </template>
              <template v-slot:actions>
                <ServiceProviderContactCard class="pa-4" style="min-width: 400px;" />
              </template>
            </EmptyPage>
          </v-col>
        </template>

        <!-- normal content -->
        <template v-else>
          <v-col cols="12" lg="4" style="background-color: #FBFBFB !important;">
            <MachineDetails :machine="machine" />
            <MobileCollapseSection>
              <Timeline :machine="machine" />
            </MobileCollapseSection>
            <!--
            <div v-if="isPhoneBool" style="background-color: rgb(243, 243, 243);">
              <div style="background-image: linear-gradient(white, transparent); height: 12px; width: 100%;" />
              <NavListItem :icon="'mdi-clock'" :title="'Order history'"
                :to="{ name: 'PublicHistory', query: $route.query }" />
              <NavListItem :icon="'mdi-paperclip'" :title="'Resources'"
                :to="{ name: 'PublicResources', query: $route.query }" />
              <div style="background-image: linear-gradient(transparent, white); height: 12px; width: 100%;" />
            </div>
            -->
          </v-col>
          <v-col cols="12" lg="4" style=" border-left: 1px solid rgb(213, 213, 213);" :style="(this.$route.name == 'PublicHistory' ? 'background-color: #F5F5F5 !important;' : '') +
      (isPcBool ? 'overflow-y: auto; max-height: calc(100vh - 71px);' : '')">
            <v-divider v-if="!isPcBool"></v-divider>
            <v-tabs
              :style="isPcBool ? 'max-width: 33vw; position: fixed; background-color: #F5F5F5; z-index: 10;' : ''">
              <v-tab v-if="!PublicModeSettings || PublicModeSettings.HistoryEnabled"
                :to="{ name: 'PublicHistory', query: $route.query }">
                {{ $t('text.orders') }}{{ machineHistoryCount ? ' (' + machineHistoryCount + ')' : '' }}
              </v-tab>
              <v-tab :to="{ name: 'PublicResources', query: $route.query }">
                {{ $t('text.resources') }}{{ machineResourceCount ? ' (' + machineResourceCount + ')' : '' }}
              </v-tab>
              <!--
              <v-tab disabled>
                Parts
                <v-card dark color="gery" class="rounded-pill pa-1 ml-2">
                  <small>0</small>
                </v-card>
              </v-tab>
               -->
            </v-tabs>

            <!--
            <div class="py-3 pl-4 pr-3 d-flex align-center">
              <v-text-field :placeholder="$t('text.find_in_list')" prepend-inner-icon="mdi-magnify" hide-details filled
                dense outlined clearable single-line>
              </v-text-field>
              <v-btn icon class="ml-3">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </div>
           
            <v-virtual-scroll :bench="benched" :items="items" height="calc(100% - 128px)" item-height="91.98">
              <template v-slot:default="{ item }">
                <MachineJobListItem :item="item" />
              </template>
</v-virtual-scroll>
-->
            <div v-if="isPcBool" style="content: ''; height: 50px;"></div>
            <router-view :allowed="allowRestrictedDataAccess" :history="machine.MachineServiceHistories"
              :resources="machine.ResourceFolder">
            </router-view>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </component>
</template>


<script>
import i18n from '@/plugins/i18n'
import http from "@/api/client";
import LanguageSelector from "@/components/Inputs/LanguageSelector.vue";
import LoginForm from "@/components/Forms/LoginForm.vue";
import EmptyPage from "@/views/EmptyPage.vue";
import PublicModeBanner from "@/views/public/PublicModeBanner.vue";
import MachineDetails from "@/views/public/PublicMachineDetails.vue";
import NavListItem from "@/views/public/NavListItem.vue";
import AppBarRouterView from "@/views/public/AppBarRouterView.vue";
//import ImitationInput from "@/components/Inputs/ImitationInput.vue";
//import CompanyContactCard from "@/components/Cards/CompanyContactCard.vue";
import ServiceProviderContactCard from "@/components/Cards/ServiceProviderContactCard.vue";
//import DeveloperContactCard from "@/components/Cards/DeveloperContactCard.vue";
import MachineJobListItem from "@/components/ListItems/MachineJobListItem.vue";
import Timeline from "@/views/public/timeline.vue";
import MobileCollapseSection from "@/views/public/MobileCollapseSection.vue";
import WideBtn from "@/components/WideBtn.vue";
import Avatar from "@/components/Cards/Avatar.vue";

export default {
  name: "PublicQRView",
  props: {},
  components: {
    //ImitationInput,
    LanguageSelector,
    LoginForm,
    EmptyPage,
    //CompanyContactCard,
    ServiceProviderContactCard,
    //DeveloperContactCard,
    PublicModeBanner,
    MachineDetails,
    MachineJobListItem,
    NavListItem,
    AppBarRouterView,
    Timeline,
    MobileCollapseSection,
    WideBtn,
    Avatar,
  },

  data() {
    return {
      machine: {
        MachineType: {},
        MachineModel: {},
        Supplier: {},
      },
      provider: null,
      benched: 0,
      allowRestrictedDataAccess: false,
      loadingProvider: false,
      loadingMachine: false,
      failedRequest: null,

      PublicModeSettings: null,
    };
  },

  created() {
    this.getPublicModeSettings();
    if (this.$route.name != 'PublicHistory') {
      this.$router.replace({ name: 'PublicHistory', query: this.$route.query })
    }
  },

  mounted() {
    this.getMachine();
    this.setFavicon("faviconPublicPortal.ico"); //method from main.js
    this.validateRedirect();
  },

  methods: {
    async updateUserInfo() {
      if (this.isLoggedIn) {
        await this.$store.dispatch("getUserInfo");
      }
    },

    async getPublicModeSettings() {
      var response = await http().get('/api2/customer/userinfo/PublicModeSettings');
      this.PublicModeSettings = response.data;
      console.log('PublicModeSettings', this.PublicModeSettings);
      if (!this.PublicModeSettings.Enabled) {
        this.$router.replace({ name: 'MachineList' })
      }
      if (!this.PublicModeSettings.HistoryEnabled && this.$route.name == 'PublicHistory') {
        this.$router.replace({ name: 'PublicResources', query: this.$route.query })
      }
    },

    async getMachine() {
      const qr = this.$route.params.qr;
      this.loadingMachine = true;
      await http()
        .get(`api2/qr/?code=${encodeURI(qr)}`)
        .then((response) => {
          console.log('get qr response', response);
          this.failedRequest = null;
          this.machine = response.data;
          this.allowRestrictedDataAccess = !!response.data.MachineServiceHistories;
        }).catch((e) => {
          console.log('failed request', e);
          this.failedRequest = e;
        }).finally(() => {
          this.loadingMachine = false;
        });
      this.validateRedirect();
    },

    countNestedFiles(folder) {
      var count = folder?.Files?.length ?? 0;
      var folderArr = Object.assign([], folder?.Folders);
      while (folderArr.length) {
        var current = folderArr.shift();
        var copy = Object.assign([], current.Folders)
        folderArr.push(...copy);
        count += current.Files ? current.Files.length : 0;
      }
      return count;
    },

    getMachineResources() {

    },

    getMachineHistory() {

    },

    async validateRedirect(go) {
      if (!this.userInfo) {
        await this.updateUserInfo();
      }
      if (this.isLoggedIn && this.youHaveOwnership) {
        console.log('redirect to machine', go);
        if (go) {
          this.$router.replace({ name: 'ViewMachine', params: { id: this.machine.Id } });
        }
      }
    },
  },

  computed: {
    userInfo() {
      if (this.$store.state.userInfo?.Email) {
        return this.$store.state.userInfo
      }
      else {
        return null
      }
    },

    isLoggedIn() {
      return this.$store.state.token != null;
    },

    authorizedCustomerIdList() {
      if (this.isLoggedIn && this.userInfo) {
        var impersinationOptionIds = this.userInfo?.ImpersonationOptions?.map(x => x.Id);
        var primaryId = this.userInfo?.Customer?.Id;
        var list = [primaryId, ...impersinationOptionIds];
        console.log('authorizedCustomerIdList', list);
        return list;
      }
      else {
        return [];
      }
    },

    youHaveOwnership() {
      return this.authorizedCustomerIdList.includes(this.machine?.CustomerId);
    },

    machineHistoryCount() {
      return this.machine?.MachineServiceHistories?.length
    },

    machineResourceCount() {
      return this.machine ? this.countNestedFiles(this.machine.ResourceFolder) : 0;
    },

    allowAutomaticSubPageRedirection() {
      return false
      /*
      if (this.isPhoneBool && this.$route.name != 'Qr' && !this.machineNotFoundBool && !this.unauthorizedBool) {
        return true
      }
      else {
        return false
      }
      */
    },
    failedRequestConfig() {
      if (!this.failedRequest) {
        return null;
      }
      else {
        var config = {
          icon: null,
          title: null,
          subtitle: null,
          actions: null,
        };
        var code = this.failedRequest.response ? this.failedRequest.response.status : this.failedRequest.status;

        switch (code) {
          case 204:
            config.icon = 'mdi-washing-machine-off';
            config.title = i18n.t('text.equipment_not_found');
            config.subtitle = i18n.t('text.equipment_not_found_body');
            break;
          case 401:
            config.icon = 'mdi-shield-lock';
            config.title = i18n.t('text.equipment_not_public');
            config.subtitle = i18n.t('text.quipment_not_public_body');
            break;
          default:
            config.icon = 'mdi-help-rhombus';
            config.title = i18n.t('text.unknown_error');
            config.subtitle = i18n.t('text.unknown_error_body');
            break;
        }

        /*
        failed
          'mdi-connection'
          $t('text.unable_to_get_equipment')
          $t('text.unable_to_get_equipment_body')
        */
        return config;
      }
    },

    loading() {
      return this.loadingMachine || this.loadingProvider;
    },
  },

  watch: {},
};
</script>


<style lang='scss'>
.text-input-blue .v-text-field__slot input {
  color: var(--v-primary-base) !important;
}

.theme--light.v-tabs>.v-tabs-bar {
  box-shadow: inset 0 -10px 2px -10px #a8a8a8;
}

.v-tabs-bar .v-tab:not(.v-tab--active) {
  background-color: rgba(0, 0, 0, 0.019);
}

/*
-moz-box-shadow: inset 0 -10px 10px -10px #b5b5b5;
-webkit-box-shadow: inset 0 -10px 10px -10px #b5b5b5;
box-shadow: inset 0 -10px 10px -10px #b5b5b5;
*/
</style>